import PropTypes from 'prop-types'

import styles from './Button.module.css'

function Button({ onClick, children, color = 'primary', ...props }) {
  const className = styles[color]

  return (
    <button type="button" {...props} className={className} onClick={onClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary']),
}

export default Button
