import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import ErrorBoundary from '~/App/ErrorBoundary'
import useConfiguration from '~/hooks/useConfiguration'

function PageProxy({ page: Page, provider: Provider = null }) {
  const { auctionId } = useParams()
  const { honeybadger } = useConfiguration()

  const contents = (
    <ErrorBoundary honeybadger={honeybadger}>
      <Page />
    </ErrorBoundary>
  )

  if (!Provider) return contents

  return <Provider auctionId={Number(auctionId)}>{contents}</Provider>
}

PageProxy.propTypes = {
  page: PropTypes.elementType.isRequired,
  provider: PropTypes.elementType,
}

export default PageProxy
