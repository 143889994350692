import { forwardRef } from 'react'

import VideoPlayer from './VideoPlayer'
import ErrorBoundary from './ErrorBoundary'

function VideoPlayerWithErrorBoundary(props, ref) {
  return (
    <ErrorBoundary>
      <VideoPlayer ref={ref} {...props} />
    </ErrorBoundary>
  )
}

export default forwardRef(VideoPlayerWithErrorBoundary)
