import React from 'react'
import PropTypes from 'prop-types'

// NOTE: This is a failsafe that should have no dependencies beyond React itself!
// We fall back to this one from ErrorBoundary in case of errors when rendering errors. (Yo dawg.)

class FailsafeErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(_error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // We don't attempt to call Honeybadger since this is a failsafe.
    // Perhaps Honeybadger is what failed.
    console.error('Error in failsafe:', error, errorInfo)
  }

  render() {
    if (!this.state.hasError) return this.props.children

    return (
      <div style={{ margin: '2em' }}>
        <h1>We're sorry, but something went wrong</h1>
        <p>Please try reloading the page.</p>
      </div>
    )
  }
}

FailsafeErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FailsafeErrorBoundary
