import PropTypes from 'prop-types'
import VolumeIconSvgDefs from './VolumeIconSvgDefs'

function step(edge, x) {
  return x < edge ? 0.0 : 1.0
}

function IconPath({ id, opacity }) {
  const style =
    typeof opacity === 'number'
      ? { opacity, transition: 'opacity .2s' }
      : undefined

  return <use xlinkHref={id} style={style} />
}

IconPath.propTypes = {
  id: PropTypes.string.isRequired,
  opacity: PropTypes.number,
}

function VolumeIcon({ volume, isMuted = false }) {
  const realVolume = isMuted ? 0.0 : volume
  const muteOpacity = isMuted ? 1.0 : 0.0
  const opacity1 = step(Number.EPSILON, realVolume)
  const opacity2 = step(1 / 3, realVolume)
  const opacity3 = step(2 / 3, realVolume)

  // This viewBox is just big enough to contain the IconPaths below.
  return (
    <svg viewBox="0 0 576 512">
      <VolumeIconSvgDefs />
      <IconPath id="#speaker" />
      <IconPath id="#mute" opacity={muteOpacity} />
      <IconPath id="#level1" opacity={opacity1} />
      <IconPath id="#level2" opacity={opacity2} />
      <IconPath id="#level3" opacity={opacity3} />
    </svg>
  )
}

VolumeIcon.propTypes = {
  volume: PropTypes.number.isRequired,
  isMuted: PropTypes.bool.isRequired,
}

export default VolumeIcon
