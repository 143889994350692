import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useI18n } from '@barsoom/lengua'

function setApiLocale(locale) {
  return (_dispatch, _getState, { api }) => {
    api.setLocale(locale)
  }
}

function ApiLocaleUpdater() {
  const dispatch = useDispatch()
  const I18n = useI18n()

  const language = I18n.locale.language

  useEffect(() => {
    dispatch(setApiLocale(language))
  }, [language, dispatch])

  return null
}

export default ApiLocaleUpdater
