import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { errorsFromPayload } from './shared'

import { replaceAuction } from '~/slices/shared'

export const registerRoomBid = createAsyncThunk(
  'mediation/registerRoomBid',
  async (
    { auctionId, lotId, amount },
    { extra: { api }, dispatch, rejectWithValue }
  ) => {
    const response = await api.post(
      `/api/v2/admin/auctions/${auctionId}/lots/${lotId}/bids`,
      { body: { amount } }
    )

    if (!response.ok) {
      return rejectWithValue(response.body)
    }

    dispatch(replaceAuction(response.body))

    return response.body
  }
)

export const approveBid = createAsyncThunk(
  'mediation/approveBid',
  async (
    { auctionId, lotId, bidId },
    { extra: { api }, dispatch, rejectWithValue }
  ) => {
    const response = await api.put(
      `/api/v2/admin/auctions/${auctionId}/lots/${lotId}/bids/${bidId}/approve`
    )

    if (!response.ok) {
      return rejectWithValue(response.body)
    }

    dispatch(replaceAuction(response.body))

    return response.body
  }
)

export const rollBackBid = createAsyncThunk(
  'mediation/rollBackBid',
  async (
    { auctionId, lotId, bidId },
    { extra: { api }, dispatch, rejectWithValue }
  ) => {
    const response = await api.delete(
      `/api/v2/admin/auctions/${auctionId}/lots/${lotId}/bids/${bidId}`
    )

    if (!response.ok) {
      return rejectWithValue(response.body)
    }

    dispatch(replaceAuction(response.body))

    return response.body
  }
)

const toggleLotClosedForBidding = createAsyncThunk(
  'mediation/toggleLotClosedForBidding',
  async (
    { auctionId, lotId, open },
    { extra: { api }, dispatch, rejectWithValue }
  ) => {
    const endpoint = open ? 'open_bidding' : 'close_bidding'

    const response = await api.put(
      `/api/v2/admin/auctions/${auctionId}/lots/${lotId}/${endpoint}`
    )

    if (!response.ok) {
      return rejectWithValue(response.body)
    }

    dispatch(replaceAuction(response.body))

    return response.body
  }
)

export function closeBidding({ auctionId, lotId }) {
  return toggleLotClosedForBidding({ auctionId, lotId, open: false })
}

export function openBidding({ auctionId, lotId }) {
  return toggleLotClosedForBidding({ auctionId, lotId, open: true })
}

const initialState = {
  registerRoomBidRequestId: null,
  approveBidRequestId: null,
  rollBackBidRequestId: null,
  toggleLotClosedForBiddingRequestId: null,
  nextBidAmount: null,
  errors: [],
}

const mediationSlice = createSlice({
  name: 'mediation',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(registerRoomBid.pending, (state, action) => {
        state.registerRoomBidRequestId = action.meta.requestId
        state.errors = []
      })
      .addCase(registerRoomBid.fulfilled, (state, action) => {
        if (state.registerRoomBidRequestId === action.meta.requestId) {
          state.registerRoomBidRequestId = null
        }
        state.nextBidAmount = action.payload.nextBidAmount
      })
      .addCase(registerRoomBid.rejected, (state, action) => {
        if (state.registerRoomBidRequestId === action.meta.requestId) {
          state.registerRoomBidRequestId = null
        }
        state.errors = errorsFromPayload(
          action.payload,
          'Could not register room bid.'
        )
      })

      .addCase(approveBid.pending, (state, action) => {
        state.approveBidRequestId = action.meta.requestId
        state.errors = []
      })
      .addCase(approveBid.fulfilled, (state, action) => {
        if (state.approveBidRequestId === action.meta.requestId) {
          state.approveBidRequestId = null
        }
        state.nextBidAmount = action.payload.nextBidAmount
      })
      .addCase(approveBid.rejected, (state, action) => {
        if (state.approveBidRequestId === action.meta.requestId) {
          state.approveBidRequestId = null
        }
        state.errors = errorsFromPayload(
          action.payload,
          'Could not approve bid.'
        )
      })

      .addCase(rollBackBid.pending, (state, action) => {
        state.rollBackBidRequestId = action.meta.requestId
        state.errors = []
      })
      .addCase(rollBackBid.fulfilled, (state, action) => {
        if (state.rollBackBidRequestId === action.meta.requestId) {
          state.rollBackBidRequestId = null
        }
        state.nextBidAmount = action.payload.nextBidAmount
      })
      .addCase(rollBackBid.rejected, (state, action) => {
        if (state.rollBackBidRequestId === action.meta.requestId) {
          state.rollBackBidRequestId = null
        }
        state.errors = errorsFromPayload(
          action.payload,
          'Could not roll back bid.'
        )
      })

      .addCase(toggleLotClosedForBidding.pending, (state, action) => {
        state.toggleLotClosedForBiddingRequestId = action.meta.requestId
      })
      .addCase(toggleLotClosedForBidding.fulfilled, (state, action) => {
        if (
          state.toggleLotClosedForBiddingRequestId === action.meta.requestId
        ) {
          state.toggleLotClosedForBiddingRequestId = null
        }
      })
      .addCase(toggleLotClosedForBidding.rejected, (state, action) => {
        if (
          state.toggleLotClosedForBiddingRequestId === action.meta.requestId
        ) {
          state.toggleLotClosedForBiddingRequestId = null
        }
        state.errors = errorsFromPayload(
          action.payload,
          'Could not close bidding.'
        )
      })
  },
})

export default mediationSlice.reducer
