import PropTypes from 'prop-types'
import styles from './NarrowLayout.module.css'

function NarrowLayout({ children }) {
  return <div className={styles.narrowLayout}>{children}</div>
}

NarrowLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NarrowLayout
