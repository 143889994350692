import PropTypes from 'prop-types'

import { Provider as MessagingProvider } from '~/lib/Messaging'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { Provider as ConfigurationProvider } from './Configuration'
import FailsafeErrorBoundary from './FailsafeErrorBoundary'
import ErrorBoundary from './ErrorBoundary'
import HandleGlobalMessages from './HandleGlobalMessages'

function App({ configuration, store, children }) {
  // The ErrorBoundary is inside MessageBusProvider so MB events can affect the error page, too.
  // The FailsafeErrorBoundary is furthest out so we can almost always show *some* error page, even if it's minimal.
  return (
    <FailsafeErrorBoundary>
      <MessagingProvider configuration={configuration}>
        <HandleGlobalMessages />
        <ErrorBoundary honeybadger={configuration.honeybadger}>
          <ConfigurationProvider configuration={configuration}>
            <ReduxProvider store={store}>
              <HelmetProvider>
                <Helmet>
                  <meta
                    name="revision"
                    content={import.meta.env.VITE_GIT_REVISION}
                  />
                </Helmet>
                {children}
              </HelmetProvider>
            </ReduxProvider>
          </ConfigurationProvider>
        </ErrorBoundary>
      </MessagingProvider>
    </FailsafeErrorBoundary>
  )
}

App.propTypes = {
  configuration: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default App
