import { Suspense } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { useI18n } from '@barsoom/lengua'

import TranslationProvider from './TranslationProvider'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '~/lib/getSupportedLocale'

import LoadingScreen from '~/components/LoadingScreen'
import NotFoundPage from '~/pages/NotFoundPage'
import BoomPage from '~/pages/BoomPage'
import DevStreamingPage from '~/pages/DevStreamingPage'

import {
  AdminAuctionProvider,
  BiddingAuctionProvider,
} from '~/components/AuctionProvider'
import PageProxy from '~/components/PageProxy'
import Pages from '~/pages'

import EnvironmentBar from '~/components/EnvironmentBar'

function SuspenseFallback() {
  const I18n = useI18n()

  return <LoadingScreen text={I18n.t('loading.page')} />
}

function Routes() {
  // NOTE: If you add a new Page, update src/pages/__mocks__/index.js.
  return (
    <Router>
      <Switch>
        <Route
          path={`/:locale(${SUPPORTED_LOCALES.join('|')})`}
          render={({ match: { url, params } }) => (
            <TranslationProvider locale={params.locale}>
              <EnvironmentBar />
              <Suspense fallback={<SuspenseFallback />}>
                <Switch>
                  <Route path={url} exact>
                    <PageProxy page={Pages.IndexPage} />
                  </Route>
                  <Route path={`${url}/auctions`} exact>
                    <Redirect to={url} />
                  </Route>
                  <Route path={`${url}/auctions/:auctionId`} exact>
                    <PageProxy
                      page={Pages.BiddingPage}
                      provider={BiddingAuctionProvider}
                    />
                  </Route>
                  <Route path={`${url}/auctions/:auctionId/landing`} exact>
                    <PageProxy
                      page={Pages.LandingPage}
                      provider={AdminAuctionProvider}
                    />
                  </Route>
                  <Route path={`${url}/auctions/:auctionId/auctioneer`} exact>
                    <PageProxy
                      page={Pages.AuctioneerPage}
                      provider={AdminAuctionProvider}
                    />
                  </Route>
                  <Route path={`${url}/auctions/:auctionId/mediation`} exact>
                    <PageProxy
                      page={Pages.MediationPage}
                      provider={AdminAuctionProvider}
                    />
                  </Route>
                  <Route path={`${url}/auctions/:auctionId/currencies`} exact>
                    <PageProxy
                      page={Pages.CurrencyPage}
                      provider={AdminAuctionProvider}
                    />
                  </Route>
                  <Route path={`${url}/auctions/:auctionId/broadcast`} exact>
                    <PageProxy
                      page={Pages.BroadcastPage}
                      provider={AdminAuctionProvider}
                    />
                  </Route>
                  <Route path={`${url}/secret/dev_streaming`} exact>
                    <DevStreamingPage />
                  </Route>
                  <Route path={`${url}/secret/boom`} exact>
                    <BoomPage />
                  </Route>
                  <Route>
                    <NotFoundPage />
                  </Route>
                </Switch>
              </Suspense>
            </TranslationProvider>
          )}
        />
        <Route path="/" exact>
          <Redirect to={`/${DEFAULT_LOCALE}`} />
        </Route>
        <Route>
          <TranslationProvider locale={DEFAULT_LOCALE}>
            <NotFoundPage />
          </TranslationProvider>
        </Route>
      </Switch>
    </Router>
  )
}

export default Routes
