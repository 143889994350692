import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './IconButton.module.css'

import icons from './icons'

function IconButton({ icon, className, ...props }) {
  const src = icons[icon]?.default

  const maskImage = src && `url(${src})`

  return (
    <button
      type="button"
      {...props}
      className={classnames(styles.iconButton, className)}
      style={{ '--mask-image': maskImage }}
      data-test-icon={icon}
    />
  )
}

IconButton.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default IconButton
