import React from 'react'
import PropTypes from 'prop-types'

import Spinner from '~/components/Spinner'

import Client from './Client'
import Controls from './Controls'
import VideoUnavailableMessage from './VideoUnavailableMessage'

import styles from './VideoPlayer.module.css'

import { nanoid } from 'nanoid'

import storedSettings from './storedSettings'

class VideoPlayer extends React.Component {
  videoRef = React.createRef()
  client = null
  idAttribute = nanoid()

  state = {
    canPlay: false,
    volume: storedSettings.getVolume(),
    isMuted: storedSettings.getMuted(),
    isPaused: true,
    videoAvailable: false,
    streamInformation: null,
    currentQuality: 0,
    showSpinner: false,

    // If feature detection fails, we prefer to show a non-functional slider to the risk of hiding a functional one.
    supportsVolumeSlider: true,
  }

  componentDidMount() {
    this.client = new Client(this.props.config, this.idAttribute)
      .on('streamInformation', (streamInformation) => {
        this.setState({
          videoAvailable: true,
          streamInformation,
        })
      })
      .on('playStarted', () => {
        this.setState({
          videoAvailable: true,
          showSpinner: false,
        })
      })
      .on('playFinished', () => {})
      .on('restartingStream', () => {
        this.setState({
          videoAvailable: false,
          showSpinner: !this.state.isPaused,
        })
      })
      .on('bitrateMeasurement', (info) => {
        console.log(
          'Target bitrate:',
          info.targetBitrate,
          ', Video bitrate:',
          info.videoBitrate,
          ', Audio bitrate:',
          info.audioBitrate
        )
      })

    if (this.videoRef.current) {
      this.videoRef.current.volume = this.state.volume
      this.videoRef.current.muted = this.state.isMuted

      this._determineIfVolumeSliderIsSupported()
    }
  }

  componentWillUnmount() {
    this.client?.shutdown()
  }

  _determineIfVolumeSliderIsSupported() {
    // Volume is read-only in iOS Mobile Safari: https://stackoverflow.com/questions/2602541/html5-video-volume
    // We feature-detect it to be robust.

    const detectionVolume = this.state.volume === 0.1 ? 0.2 : 0.1
    this.videoRef.current.volume = detectionVolume

    // Without the timeout, the field would appear writeable in Mobile Safari even when not.
    setTimeout(() => {
      if (!this.videoRef.current) return

      const supportsVolumeSlider =
        this.videoRef.current.volume === detectionVolume
      this.videoRef.current.volume = this.state.volume
      this.setState({ supportsVolumeSlider })
    }, 0)
  }

  handleVideoPlay = () => {
    this.setState({ isPaused: false })
  }

  handleVideoPause = () => {
    this.setState({
      isPaused: true,
      showSpinner: false,
    })
  }

  handleTogglePlayPause = () => {
    if (this.state.isPaused) {
      this.setState({ showSpinner: true })
      this.client.play()
    } else {
      this.client.stop()
    }
  }

  handleVolumeChange = (e) => {
    const volume = Number(e.target.value)

    this.setState({ volume, isMuted: false }, () => {
      storedSettings.setVolume(volume)
      storedSettings.setMuted(false)

      this.videoRef.current.volume = volume
      this.videoRef.current.muted = false
    })
  }

  handleToggleMute = () => {
    const isMuted = !this.state.isMuted

    this.setState({ isMuted }, () => {
      storedSettings.setMuted(isMuted)
      this.videoRef.current.muted = isMuted
    })
  }

  handleSetQuality = (quality) => {
    this.client.setQuality(quality)
    this.setState({ currentQuality: quality })
  }

  render() {
    const videoStyle = {
      visibility: this.state.videoAvailable ? 'visible' : 'hidden',
    }

    const isPlaying = !(this.state.isPaused || this.state.showSpinner)

    const controls = this.state.videoAvailable && (
      <Controls
        isPaused={this.state.isPaused}
        isPlaying={isPlaying}
        isMuted={this.state.isMuted}
        volume={this.state.volume}
        showVolumeSlider={this.state.supportsVolumeSlider}
        onToggleMute={this.handleToggleMute}
        onChangeVolume={this.handleVolumeChange}
        onTogglePlayPause={this.handleTogglePlayPause}
      />
    )

    const spinner = this.state.showSpinner && (
      <Spinner color="var(--gray-800)" className={styles.spinner} />
    )

    const message = !this.state.showSpinner && !this.state.videoAvailable && (
      <VideoUnavailableMessage message={this.props.videoUnavailableMessage} />
    )

    return (
      <div>
        {/* disablePictureInPicture because of an issue in Chrome: https://trello.com/c/Qs51O8yq */}
        <video
          ref={this.videoRef}
          id={this.idAttribute}
          className={styles.video}
          onPause={this.handleVideoPause}
          onPlay={this.handleVideoPlay}
          style={videoStyle}
          onClick={this.handleTogglePlayPause}
          disablePictureInPicture
          playsInline
        />
        {controls}
        {message}
        {spinner}
      </div>
    )
  }
}

VideoPlayer.propTypes = {
  config: PropTypes.shape({
    websocketUrl: PropTypes.string.isRequired,
    iceServers: PropTypes.array.isRequired,
    streamId: PropTypes.string.isRequired,
    streamToken: PropTypes.string.isRequired,
  }).isRequired,
  videoUnavailableMessage: PropTypes.string,
}

export default VideoPlayer
