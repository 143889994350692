import PropTypes from 'prop-types'

import { useI18n } from '@barsoom/lengua'
import useConfiguration from '~/hooks/useConfiguration'

import LanguageSwitcher from './LanguageSwitcher'

import styles from './EnvironmentBar.module.css'

const NOT_AVAILABLE = <span>N/A</span>

function GitRevision() {
  if (!import.meta.env.VITE_GIT_REVISION) {
    return NOT_AVAILABLE
  }

  return <span>{import.meta.env.VITE_GIT_REVISION.slice(0, 7)}</span>
}

function BuildTime() {
  const I18n = useI18n()
  const timestamp = Number(import.meta.env.VITE_BUILD_TIME)

  if (isNaN(timestamp)) {
    return NOT_AVAILABLE
  }

  const formatted = new Intl.DateTimeFormat(I18n.localeName, {
    dateStyle: 'short',
    timeStyle: 'full',
  }).format(new Date(timestamp * 1000))

  return <span>{formatted}</span>
}

function Item({ label, children }) {
  return (
    <div className={styles.item}>
      <dt className={styles.itemLabel}>{label}</dt>
      <dd className={styles.itemContent}>{children}</dd>
    </div>
  )
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

function shouldDisplayEnvironmentBar(config) {
  if (config.visible === 'always') return true

  try {
    return localStorage.getItem('showEnvBar') === 'true'
  } catch {
    return false
  }
}

function EnvironmentBar() {
  const configuration = useConfiguration()
  const envBarConfig = configuration.envBar

  if (!shouldDisplayEnvironmentBar(envBarConfig)) {
    return null
  }

  const style = {
    '--background': envBarConfig.background,
  }

  return (
    <div className={styles.environmentBar} style={style}>
      <dl className={styles.list}>
        <Item label="label">{envBarConfig.label}</Item>
        <Item label="node_env">{process.env.NODE_ENV}</Item>
        <Item label="rev">
          <GitRevision />
        </Item>
        <Item label="date">
          <BuildTime />
        </Item>
        <Item label="lang">
          <LanguageSwitcher />
        </Item>
      </dl>
    </div>
  )
}

export default EnvironmentBar
