import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import createHoneybadgerMiddleware from '~/lib/createHoneybadgerMiddleware'

import * as reducers from '~/slices'

import Api from '~/lib/Api'

// Returns obj stripped of keys that are Symbols.
//
// Modules include the symbol key { [Symbol.toStringTag]: 'module' }
// and configureStore will get confused if we don't remove it.
function makePlainObject(obj) {
  return Object.fromEntries(Object.entries(obj))
}

function createStore(configuration) {
  if (!reducers) {
    throw new Error('Need reducers to createStore.')
  }

  const reducer = makePlainObject(reducers)

  const api = new Api({
    baseUrl: configuration.backendBaseUrl,
    locale: configuration.initialLocale,
  })

  const store = configureStore({
    devTools: true,
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument: { api } },
      }).concat(createHoneybadgerMiddleware(configuration.honeybadger)),
  })

  // https://vitejs.dev/guide/api-hmr.html#hot-accept-deps-cb
  // https://redux-toolkit.js.org/usage/usage-guide#manual-store-setup
  if (process.env.NODE_ENV === 'development' && import.meta.hot) {
    import.meta.hot.accept('../slices/index.js', (newReducers) => {
      store.replaceReducer(combineReducers(makePlainObject(newReducers)))
    })
  }

  return store
}

export default createStore
