import PropTypes from 'prop-types'

import { useI18n } from '@barsoom/lengua'
import VideoPlayer from '~/components/VideoPlayer'

import styles from './Player.module.css'

function Player({ config }) {
  const I18n = useI18n('player')

  // The player is pretty tricky, so we test it separately.
  const streamConfigured =
    process.env.NODE_ENV !== 'test' && config?.streamToken

  if (!streamConfigured) {
    return (
      <p className={styles.streamNotConfigured} data-testid="player">
        {I18n.t('.streamNotConfigured')}
      </p>
    )
  }

  // Force VideoPlayer to re-mount when the config changes.
  const videoPlayerKey = JSON.stringify(config)

  return (
    <VideoPlayer
      key={videoPlayerKey}
      config={config}
      videoUnavailableMessage={I18n.t('.waitingForStreamToStart')}
    />
  )
}

Player.propTypes = {
  config: PropTypes.object,
}

export default Player
