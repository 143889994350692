import { Honeybadger } from '@honeybadger-io/react'

function isMessageBusBreadcrumb(opts) {
  // We do requests to the message-bus endpoint very frequently
  // and they turn into noise. If anything critical happens
  // related to message bus we will probably get an exception.

  if (opts?.category !== 'request') return false

  const url = String(opts?.metadata?.url)

  return url.includes('/message-bus/')
}

function monkeypatchHoneybadger(honeybadger) {
  if (honeybadger.__monkeypatched) return honeybadger

  const origAddBreadcrumb = honeybadger.addBreadcrumb

  function monkeypatchedAddBreadcrumb(message, opts) {
    if (opts && isMessageBusBreadcrumb(opts)) return this

    return origAddBreadcrumb.call(this, message, opts)
  }

  honeybadger.addBreadcrumb = monkeypatchedAddBreadcrumb.bind(honeybadger)
  honeybadger.__monkeypatched = true

  return honeybadger
}

function configureHoneybadger(environment) {
  const apiKey = import.meta.env.VITE_HONEYBADGER_API_KEY
  const revision = import.meta.env.VITE_GIT_REVISION

  if (!apiKey && process.env.NODE_ENV !== 'test') {
    console.warn('Missing honeybadger API key')
  }

  const honeybadger = Honeybadger.configure({
    apiKey,
    environment,
    revision,
    debug: false,
  })

  honeybadger.beforeNotify((notice) => {
    // Ignore errors from known bots, e.g. https://app.honeybadger.io/projects/90692/faults/91081242.
    if (navigator.userAgent?.includes('Linespider')) return false

    // Safe to ignore: Firefox extension.
    if (notice.stack?.includes('moz-extension://')) return false

    // Safe to ignore: unactionable error from Cloudflare's analytics script.
    if (
      notice.message === 'Illegal invocation' &&
      notice.stack?.includes('static.cloudflareinsights.com/beacon.min.js')
    )
      return false

    const messageSubstringsToIgnore = [
      // Seen in https://app.honeybadger.io/projects/90692/faults/95388971 and https://app.honeybadger.io/projects/90692/faults/95388972.
      // Assumed to be old browsers not supporting RTCIceCandidate or RTCPeerConnection.
      // Not sure why we've seen it on Safari 16.3 which is supposed to support this; maybe a bot lying about its user agent?
      "Can't find variable: RTCIceCandidate",
      "Can't find variable: RTCPeerConnection",

      // Safe to ignore: https://stackoverflow.com/a/64257593/6962
      'ResizeObserver loop completed with undelivered notifications',
    ]

    if (
      messageSubstringsToIgnore.some((string) =>
        notice.message?.includes(string)
      )
    )
      return false

    // Likely from Microsoft Outlook SafeLink Crawler:
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    if (notice.message?.includes('Object Not Found Matching Id:')) {
      // We've seen other, less clearly identified errors follow on from this one (they have
      // this one in their breadcrumbs). This is the least bad way we know to identify them.
      // So we just ignore all future errors.
      honeybadger.config.maxErrors = 0

      // Ignore this error.
      return false
    }
  })

  return monkeypatchHoneybadger(honeybadger)
}

export default configureHoneybadger
