function noop(..._args) {}

function generateStyle(color) {
  return [
    `background: ${color}`,
    `border: 1px solid rgba(0, 0, 0, 0.5)`,
    `border-radius: 2px`,
    `padding: 0 2px`,
    `color: #000`,
    `font-weight: bold`,
  ].join(';')
}

const noopLogger = {
  log: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
}

function createLogger(title, color, { enabledInTest = false } = {}) {
  if (!enabledInTest && process.env.NODE_ENV === 'test') {
    return noopLogger
  }

  const style = generateStyle(color)
  const format = `%c${title}`

  return {
    log: console.log.bind(console, format, style),
    debug: console.debug.bind(console, format, style),
    info: console.info.bind(console, format, style),
    warn: console.warn.bind(console, format, style),
    error: console.error.bind(console, format, style),
  }
}

export default createLogger
