import PropTypes from 'prop-types'

import { Provider as MessageBusProvider } from '~/lib/MessageBus'

function Provider({ configuration, children }) {
  return (
    <MessageBusProvider
      pollingInterval={configuration.messageBusPollingInterval}
      baseUrl={configuration.backendBaseUrl}
    >
      {children}
    </MessageBusProvider>
  )
}

Provider.propTypes = {
  configuration: PropTypes.shape({
    backendBaseUrl: PropTypes.string.isRequired,
    messageBusPollingInterval: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default Provider
