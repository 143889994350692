// These paths are copied by hand from https://fontawesome.com/v5.15/icons/volume-up
// and https://fontawesome.com/v5.15/icons/volume-mute,
// and then edited with https://yqnn.github.io/svg-path-editor/

function VolumeIconSvgDefs() {
  return (
    <defs>
      <path
        id="speaker"
        d="M 215.03 71.05 L 126.06 160 H 24 C 10.74 160 0 170.74 0 184 V 328 C 0 341.25 10.74 352 24 352 H 126.06 L 215.03 440.95 C 230.06 455.98 256 445.42 256 423.98 V 88.02 C 256 66.56 230.04 56.04 215.03 71.05 Z"
      />
      <path
        id="level1"
        d="M 338.23 179.13 C 326.65 172.8 312.04 176.97 305.62 188.58 C 299.23 200.19 303.46 214.78 315.07 221.19 C 327.98 228.28 336 241.63 336 256 C 336 270.38 327.98 283.72 315.08 290.81 C 303.47 297.22 299.24 311.81 305.63 323.42 C 312.06 335.08 326.68 339.22 338.24 332.87 C 366.47 317.32 384.01 287.87 384.01 255.99 S 366.47 194.67 338.23 179.13 Z"
      />
      <path
        id="level2"
        d="M 480 256 C 480 192.47 447.94 134.06 394.23 99.76 C 383.04 92.62 368.2 95.94 361.11 107.22 S 357.33 133.43 368.52 140.58 C 408.27 165.97 432 209.11 432 256 S 408.27 346.03 368.52 371.42 C 357.33 378.56 354.02 393.49 361.11 404.78 C 367.62 415.14 382.23 419.92 394.23 412.24 C 447.94 377.94 480 319.54 480 256 Z"
      />
      <path
        id="level3"
        d="M 448.35 19.97 C 437.18 12.64 422.17 15.73 414.84 26.92 C 407.5 38.09 410.62 53.1 421.79 60.43 C 488.06 103.92 527.61 177.03 527.61 256.01 C 527.61 334.99 488.06 408.1 421.79 451.59 C 410.62 458.91 407.5 473.93 414.84 485.09 C 421.88 495.8 436.77 499.65 448.35 492.04 C 528.27 439.58 576 351.33 576 256 S 528.27 72.43 448.35 19.97 Z"
      />
      <path
        id="mute"
        d="M 461.64 256 L 507.28 210.36 C 513.58 204.06 513.58 193.84 507.28 187.54 L 484.46 164.72 C 478.16 158.42 467.94 158.42 461.64 164.72 L 416 210.36 L 370.36 164.72 C 364.06 158.42 353.84 158.42 347.54 164.72 L 324.72 187.54 C 318.42 193.84 318.42 204.06 324.72 210.36 L 370.36 256 L 324.73 301.63 C 318.43 307.93 318.43 318.15 324.73 324.45 L 347.55 347.27 C 353.85 353.57 364.07 353.57 370.37 347.27 L 416 301.64 L 461.64 347.28 C 467.94 353.58 478.16 353.58 484.46 347.28 L 507.28 324.46 C 513.58 318.16 513.58 307.94 507.28 301.64 L 461.64 256 Z"
      />
    </defs>
  )
}

export default VolumeIconSvgDefs
