function iconNameFromFilename(filename) {
  // Turns './hello-world.svg' into 'hello-world'
  return filename.replace(/^\.\/(.*?)\.svg$/, '$1')
}

const icons = Object.fromEntries(
  Object.entries(import.meta.globEager('./*.svg')).map(
    ([filename, importFn]) => [iconNameFromFilename(filename), importFn]
  )
)

export default icons
