import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import getYourLastBid from '~/lib/getYourLastBid'

import { replaceAuction, errorsFromPayload, auctionFromPayload } from './shared'

import {
  REQUEST_STATUS,
  responseStatusToRequestStatus,
} from '~/components/RequestState'

export const fetchAuction = createAsyncThunk(
  'bidding/fetchAuction',
  async ({ auctionId }, { extra: { api }, rejectWithValue }) => {
    const response = await api.get(`/api/v2/bidder/auctions/${auctionId}`)

    if (response.ok) {
      return response.body
    } else if (response.status) {
      return rejectWithValue(response.status)
    } else {
      throw new Error('could not fetch auction')
    }
  }
)

export const joinTheBidding = createAsyncThunk(
  'bidding/joinTheBidding',
  async ({ auctionId }, { dispatch, getState }) => {
    if (getState().bidding.paddleNumber) return

    // If they don't have a paddle number, we request one.
    return dispatch(requestPaddle({ auctionId }))
  }
)

const requestPaddle = createAsyncThunk(
  'bidding/requestPaddle',
  async ({ auctionId }, { extra: { api }, dispatch, rejectWithValue }) => {
    const response = await api.post(
      `/api/v2/bidder/auctions/${auctionId}/paddles`
    )

    if (!response.ok) {
      return rejectWithValue(response.body)
    }

    dispatch(replaceAuction(response.body.auction))

    return response.body.paddleNumber
  }
)

export const placeBid = createAsyncThunk(
  'bidding/placeBid',
  async (
    { auctionId, lotId, amount },
    { extra: { api }, dispatch, rejectWithValue }
  ) => {
    const response = await api.post(
      `/api/v2/bidder/auctions/${auctionId}/lots/${lotId}/bids`,
      {
        body: { amount },
      }
    )

    if (!response.ok) {
      return rejectWithValue(response.body)
    }

    dispatch(replaceAuction(response.body))

    return response.body
  }
)

const initialState = {
  auction: null,
  auctionRequestId: null,
  paddleNumber: null,
  yourLastBid: null,
  placeBidRequestId: null,
  requestPaddleRequestId: null,
  hasJoinedTheBidding: false,
  isLoggedIn: false,
  requestStatus: null,
  errors: [],
}

const biddingSlice = createSlice({
  name: 'bidding',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchAuction.pending, (state, action) => {
        state.auctionRequestId = action.meta.requestId
        state.requestStatus = REQUEST_STATUS.loading
      })
      .addCase(fetchAuction.fulfilled, (state, action) => {
        if (state.auctionRequestId === action.meta.requestId) {
          state.auctionRequestId = null
        } else {
          return
        }

        const { auction, ...rest } = action.payload

        Object.assign(state, rest)

        state.auction = auctionFromPayload(auction)
        state.requestStatus = REQUEST_STATUS.success
        state.yourLastBid = getYourLastBid({
          bids: state.auction.currentLot?.bids || [],
          paddleNumber: state.paddleNumber,
        })
      })
      .addCase(fetchAuction.rejected, (state, action) => {
        if (state.auctionRequestId === action.meta.requestId) {
          state.auctionRequestId = null
        } else {
          return
        }

        if (action.meta.rejectedWithValue) {
          state.requestStatus = responseStatusToRequestStatus(action.payload)
        } else {
          state.requestStatus = REQUEST_STATUS.failure
        }
      })

      .addCase(placeBid.pending, (state, action) => {
        state.placeBidRequestId = action.meta.requestId
        state.errors = []
      })
      .addCase(placeBid.fulfilled, (state, action) => {
        if (state.placeBidRequestId === action.meta.requestId) {
          state.placeBidRequestId = null
        }
      })
      .addCase(placeBid.rejected, (state, action) => {
        if (state.placeBidRequestId === action.meta.requestId) {
          state.placeBidRequestId = null
        }
        state.errors = errorsFromPayload(action.payload, 'Could not place bid.')
      })

      .addCase(requestPaddle.pending, (state, action) => {
        state.requestPaddleRequestId = action.meta.requestId
        state.errors = []
      })
      .addCase(requestPaddle.fulfilled, (state, action) => {
        if (state.requestPaddleRequestId === action.meta.requestId) {
          state.requestPaddleRequestId = null
        }
        state.paddleNumber = action.payload
      })
      .addCase(requestPaddle.rejected, (state, action) => {
        if (state.requestPaddleRequestId === action.meta.requestId) {
          state.requestPaddleRequestId = null
        }
        state.errors = errorsFromPayload(
          action.payload,
          'Could not join the bidding.'
        )
      })
      .addCase(joinTheBidding.fulfilled, (state) => {
        state.hasJoinedTheBidding = true
      })
      .addCase(replaceAuction, (state, action) => {
        const newAuction = auctionFromPayload(action.payload)

        state.auction = newAuction
        state.auctionRequestId = null
        state.requestStatus = REQUEST_STATUS.success
        state.yourLastBid = newAuction.currentLot
          ? getYourLastBid({
              bids: state.auction.currentLot?.bids || [],
              paddleNumber: state.paddleNumber,
            })
          : null
      })
  },
})

export const { bidApproved, bidRollback } = biddingSlice.actions

export default biddingSlice.reducer
