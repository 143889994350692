import { useI18n } from '@barsoom/lengua'

import Spinner from '~/components/Spinner'
import styles from './LoadingScreen.module.css'

function LoadingScreen() {
  const I18n = useI18n()

  return (
    <div className={styles.suspenseFallback}>
      <p className={styles.text}>
        <Spinner className={styles.spinner} color="var(--gray-800)" />
        {I18n.t('loading')}
      </p>
    </div>
  )
}

export default LoadingScreen
