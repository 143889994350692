import PropTypes from 'prop-types'

import { useI18n } from '@barsoom/lengua'

import ErrorPage from '~/pages/ErrorPage'
import NotFoundPage from '~/pages/NotFoundPage'

import LoadingScreen from '~/components/LoadingScreen'

export function responseStatusToRequestStatus(responseStatus) {
  if (responseStatus >= 200 && responseStatus <= 299) {
    return REQUEST_STATUS.success
  } else if (responseStatus === 401) {
    return REQUEST_STATUS.unauthorized
  } else if (responseStatus === 404) {
    return REQUEST_STATUS.notFound
  } else {
    return REQUEST_STATUS.failure
  }
}

export const REQUEST_STATUS = {
  loading: 'loading',
  success: 'success',
  notFound: 'not_found',
  unauthorized: 'unauthorized',
  failure: 'failure',
}

function RequestState({ requestStatus, children }) {
  const I18n = useI18n('errorPage')

  switch (requestStatus) {
    case null:
    case REQUEST_STATUS.loading:
      return <LoadingScreen />
    case REQUEST_STATUS.success:
      return <>{children}</>
    case REQUEST_STATUS.notFound:
      return <NotFoundPage />
    case REQUEST_STATUS.unauthorized:
      return (
        <ErrorPage
          title={I18n.t('.unauthorized.title')}
          body={I18n.t('.unauthorized.body')}
        />
      )
    case REQUEST_STATUS.failure:
    default:
      return (
        <ErrorPage
          title={I18n.t('.failure.title')}
          body={I18n.t('.failure.body')}
        />
      )
  }
}

RequestState.propTypes = {
  requestStatus: PropTypes.oneOf([
    REQUEST_STATUS.loading,
    REQUEST_STATUS.notFound,
    REQUEST_STATUS.unauthorized,
    REQUEST_STATUS.failure,
    REQUEST_STATUS.success,
  ]),
  children: PropTypes.node,
}

export default RequestState
