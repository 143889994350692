const KEYS = {
  VOLUME: 'liveBiddingVideoVolume',
  MUTED: 'liveBiddingVideoMuted',
}

const DEFAULT_VOLUME = 0.5

export default {
  getVolume() {
    const storedVolume = localStorage.getItem(KEYS.VOLUME)

    if (storedVolume === null) return DEFAULT_VOLUME

    return Number(storedVolume)
  },

  setVolume(volume) {
    localStorage.setItem(KEYS.VOLUME, String(volume))
  },

  getMuted() {
    return localStorage.getItem(KEYS.MUTED) === 'true'
  },

  setMuted(muted) {
    localStorage.setItem(KEYS.MUTED, String(muted))
  },
}
