import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import NarrowLayout from '~/components/NarrowLayout'

function ErrorPage({ title, body }) {
  return (
    <>
      <Helmet defer={false}>
        <title>{title}</title>
      </Helmet>

      <NarrowLayout>
        <h1>{title}</h1>
        {body && <p>{body}</p>}
      </NarrowLayout>
    </>
  )
}

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
}

export default ErrorPage
