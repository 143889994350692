import { useContext, useRef, useEffect, useCallback } from 'react'
import camelcaseKeys from 'camelcase-keys'

import Context from './Context'

// Magic number in MessageBus – see `MessageBusClient`.
const receiveAllNewMessages = -1

function useMessageBus(channel, onMessage) {
  const messageBus = useContext(Context)

  if (!messageBus)
    throw new Error('Cannot useMessageBus outside MessageBus Provider!')

  const lastMessageIdRef = useRef(receiveAllNewMessages)

  const handleMessage = useCallback(
    (payload, _globalId, messageId) => {
      lastMessageIdRef.current = messageId

      onMessage(camelcaseKeys(payload, { deep: true }))
    },
    [onMessage]
  )

  useEffect(() => {
    if (!channel) {
      return
    }

    messageBus.subscribe(
      channel,
      handleMessage,
      lastMessageIdRef.current ?? receiveAllNewMessages
    )

    return () => {
      messageBus.unsubscribe(channel)
    }
  }, [channel, handleMessage, messageBus])
}

export default useMessageBus
