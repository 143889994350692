import PropTypes from 'prop-types'

import { HelmetProvider } from 'react-helmet-async'
import { HoneybadgerErrorBoundary } from '@honeybadger-io/react'

import ErrorPage from '~/pages/ErrorPage'

function ErrorComponent() {
  // Intentionally not localized to reduce the risk of further errors.
  const title = `We're sorry, but something went wrong`
  const body =
    'We have been notified about the problem. Please try reloading the page.'

  return (
    <HelmetProvider>
      <ErrorPage title={title} body={body} />
    </HelmetProvider>
  )
}

function ErrorBoundary({ honeybadger, children }) {
  return (
    <HoneybadgerErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={ErrorComponent}
    >
      {children}
    </HoneybadgerErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  honeybadger: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
