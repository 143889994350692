import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import MessageBusClient from './MessageBusClient'

import Context from './Context'

import TestDebugStatus from './TestDebugStatus'

function Provider({ pollingInterval = 300, baseUrl = '/', children }) {
  const client = useMemo(() => {
    return new MessageBusClient({
      baseUrl,
      pollingInterval,
    })
  }, [baseUrl, pollingInterval])

  useEffect(
    () => () => {
      client?.stop()
    },
    [client]
  )

  return (
    <Context.Provider value={client}>
      {process.env.NODE_ENV === 'test' && <TestDebugStatus />}
      {children}
    </Context.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  pollingInterval: PropTypes.number,
  baseUrl: PropTypes.string,
}

export default Provider
