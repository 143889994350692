import ReactDOM from 'react-dom'

import setUpApp from '~'
import Configuration from '~/App/Configuration'

const revision = import.meta.env.VITE_GIT_REVISION

const configFileName =
  process.env.NODE_ENV === 'development'
    ? '/config/development.json'
    : `/config-${revision}.json`

async function main() {
  const config = await Configuration.load(configFileName)

  const app = await setUpApp(config)

  ReactDOM.render(app, document.getElementById('root'))
}

main()
