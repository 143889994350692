import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import useConfiguration from '~/hooks/useConfiguration'
import Button from '~/components/Button'
import NarrowLayout from '~/components/NarrowLayout'
import Api from '~/lib/Api'
import Player from '../BiddingPage/Player'
import styles from '../BiddingPage/BiddingPage.module.css'
import RequestState, {
  REQUEST_STATUS,
  responseStatusToRequestStatus,
} from '~/components/RequestState'

function DevStreamingPage() {
  const { backendBaseUrl } = useConfiguration()
  const [requestStatus, setRequestStatus] = useState()
  const [playerConfig, setPlayerConfig] = useState()
  const [ingestURL, setIngestURL] = useState()
  const [skipTurnServer, setSkipTurnServer] = useState(false)
  const [useAlternativeRegion, setUseAlternativeRegion] = useState(false)

  useEffect(() => {
    // Effect callbacks may not themselves be async.
    async function runEffect() {
      const api = new Api({ baseUrl: backendBaseUrl })
      const requestParams = {
        skip_turn_server: skipTurnServer,
        use_alternative_region: useAlternativeRegion,
      }
      const response = await api.get('/api/v2/admin/dev_streaming', {
        query: requestParams,
      })

      if (response.ok) {
        setRequestStatus(REQUEST_STATUS.success)

        const { player, streaming } = response.body
        setPlayerConfig(player)
        setIngestURL(streaming.ingestUrl)
      } else if (response.status) {
        setRequestStatus(responseStatusToRequestStatus(response.status))
      } else {
        setRequestStatus(REQUEST_STATUS.failure)
      }
    }

    runEffect()
  }, [backendBaseUrl, skipTurnServer, useAlternativeRegion])

  function ToggleTurnServerButton({ children }) {
    return (
      <Button
        onClick={() => {
          setSkipTurnServer(!skipTurnServer)
        }}
      >
        {children}
      </Button>
    )
  }
  ToggleTurnServerButton.propTypes = {
    children: PropTypes.node,
  }

  function ToggleRegionButton({ children }) {
    return (
      <Button
        onClick={() => {
          setUseAlternativeRegion(!useAlternativeRegion)
        }}
      >
        {children}
      </Button>
    )
  }
  ToggleRegionButton.propTypes = {
    children: PropTypes.node,
  }

  // Intentionally not localised since this is a dev page.
  return (
    <>
      <Helmet defer={false}>
        <title>Dev stream</title>
      </Helmet>

      <RequestState requestStatus={requestStatus}>
        <NarrowLayout>
          <h2>Dev stream</h2>

          {useAlternativeRegion ? (
            <p>
              Using alternative region for this stream.{' '}
              <ToggleRegionButton>Use production region</ToggleRegionButton>
            </p>
          ) : (
            <p>
              Using production region for this stream.{' '}
              <ToggleRegionButton>Use alternative region</ToggleRegionButton>
            </p>
          )}

          {skipTurnServer ? (
            <p>
              Skipping TURN server for this stream.{' '}
              <ToggleTurnServerButton>Use TURN server</ToggleTurnServerButton>
            </p>
          ) : (
            <p>
              Using TURN server for this stream.{' '}
              <ToggleTurnServerButton>Skip TURN server</ToggleTurnServerButton>
            </p>
          )}

          <h2>Streaming</h2>

          <p>
            <b>Ingest URL:</b> {ingestURL}
          </p>

          <h2>Viewing</h2>

          <div className={styles.player}>
            <Player config={playerConfig} />
          </div>
        </NarrowLayout>
      </RequestState>
    </>
  )
}

export default DevStreamingPage
