import PropTypes from 'prop-types'

import styles from './VideoUnavailableMessage.module.css'

function VideoUnavailableMessage({ message }) {
  return <p className={styles.message}>{message}</p>
}

VideoUnavailableMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default VideoUnavailableMessage
