import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { replaceAuction, auctionFromPayload } from './shared'
import {
  REQUEST_STATUS,
  responseStatusToRequestStatus,
} from '~/components/RequestState'

export const fetchAuction = createAsyncThunk(
  'admin/fetchAuction',
  async ({ auctionId }, { extra: { api }, rejectWithValue }) => {
    const response = await api.get(`/api/v2/admin/auctions/${auctionId}`)

    if (response.ok) {
      return response.body
    } else if (response.status) {
      return rejectWithValue(response.status)
    } else {
      throw new Error('could not fetch auction')
    }
  }
)

const initialState = {
  auction: null,
  auctionRequestId: null,
  requestStatus: null,
}

const adminSlice = createSlice({
  name: 'admin',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchAuction.pending, (state, action) => {
        state.auctionRequestId = action.meta.requestId
        state.requestStatus = REQUEST_STATUS.loading
      })
      .addCase(fetchAuction.fulfilled, (state, action) => {
        if (state.auctionRequestId === action.meta.requestId) {
          state.auctionRequestId = null
        } else {
          return
        }

        const { auction, ...rest } = action.payload

        Object.assign(state, rest)
        state.auction = auctionFromPayload(auction)
        state.requestStatus = REQUEST_STATUS.success
      })
      .addCase(fetchAuction.rejected, (state, action) => {
        if (state.auctionRequestId === action.meta.requestId) {
          state.auctionRequestId = null
        } else {
          return
        }

        if (action.meta.rejectedWithValue) {
          state.requestStatus = responseStatusToRequestStatus(action.payload)
        } else {
          state.requestStatus = REQUEST_STATUS.failure
        }
      })
      .addCase(replaceAuction, (state, action) => {
        const auction = auctionFromPayload(action.payload)

        if (state.auction?.id === auction.id) {
          // The original auction state may contain admin-only keys like `streaming`.
          // But auction updates may be without those admin-only keys, as the updates are shared between admin and public.
          // So we shallowly merge the new data into the old.
          // Discussion: https://github.com/barsoom/live-bidding-frontend/commit/dbcc46772b2bb3bcc88e1d0f092ae4074d91a0fb#commitcomment-60283284
          state.auction = { ...state.auction, ...auction }
        } else {
          state.auction = auction
        }

        state.auctionRequestId = null
        state.requestStatus = REQUEST_STATUS.success
      })
  },
})

export default adminSlice.reducer
