import React from 'react'
import PropTypes from 'prop-types'

import { useI18n } from '@barsoom/lengua'
import useConfiguration from '~/hooks/useConfiguration'

import styles from './ErrorBoundary.module.css'

function withHoneybadger(Component) {
  const Hoc = (props) => {
    const { honeybadger } = useConfiguration()
    return <Component {...props} honeybadger={honeybadger} />
  }

  const componentName = Component.displayName || Component.name || 'Unknown'
  Hoc.displayName = `withHoneybadger(${componentName})`

  return Hoc
}

function ErrorMessage({ onClickRetry }) {
  const I18n = useI18n('player.errors')

  return (
    <div className={styles.errorBoundary}>
      <h1 className={styles.title}>{I18n.t('.somethingWentWrong')}</h1>
      <p>
        <button type="button" className={styles.button} onClick={onClickRetry}>
          {I18n.t('.tryAgain')}
        </button>
      </p>
    </div>
  )
}

ErrorMessage.propTypes = {
  onClickRetry: PropTypes.func.isRequired,
}

class ErrorBoundary extends React.Component {
  static propTypes = {
    honeybadger: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    this.props.honeybadger.notify(error)
  }

  reset() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage onClickRetry={() => this.reset()} />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  honeybadger: PropTypes.shape({
    notify: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default withHoneybadger(ErrorBoundary)
