import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import useAuctionMessages from './useAuctionMessages'

import RequestState from '~/components/RequestState'

export const AUCTION_PROVIDER_TEST_ID = 'AuctionProviderTestDebugStatus'

function TestDebugStatus({ auctionId, requestStatus }) {
  return (
    <input
      type="hidden"
      data-testid={AUCTION_PROVIDER_TEST_ID}
      data-auction-id={auctionId}
      data-request-status={requestStatus}
    />
  )
}

TestDebugStatus.propTypes = {
  auctionId: PropTypes.number,
  requestStatus: PropTypes.string,
}

function AbstractAuctionProvider({
  auctionId,
  action,
  selector,
  Context,
  children,
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action({ auctionId }))
  }, [auctionId, action, dispatch])

  const state = useSelector(selector)

  useAuctionMessages(auctionId)

  const testDebugStatus = process.env.NODE_ENV === 'test' && (
    <TestDebugStatus
      auctionId={state.auction?.id}
      requestStatus={state.requestStatus}
    />
  )

  return (
    <RequestState requestStatus={state.requestStatus}>
      <Context.Provider value={state}>
        {testDebugStatus}
        {children}
      </Context.Provider>
    </RequestState>
  )
}

AbstractAuctionProvider.propTypes = {
  auctionId: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  Context: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default AbstractAuctionProvider
