import PropTypes from 'prop-types'
import styles from './RangeSlider.module.css'

function RangeSlider({
  value = 0,
  min = 0,
  max = 100,
  step = 1,
  title,
  onChange,
}) {
  return (
    <input
      type="range"
      title={title}
      className={styles.range}
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
    />
  )
}

RangeSlider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
}

export default RangeSlider
