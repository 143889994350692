import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import qs from 'query-string'
import logThatBackendIsDown from '~/lib/logThatBackendIsDown'

function stripLeadingSlashes(str) {
  return str.replace(/^\/+/, '')
}

function stripTrailingSlashes(str) {
  return str.replace(/\/+$/, '')
}

async function transformResponse(response) {
  const json = await response.json()
  const body = camelcaseKeys(json, { deep: true })

  return {
    ok: response.ok,
    status: response.status,
    body,
  }
}

function appendQuery(url, query = {}) {
  const queryString = qs.stringify(snakecaseKeys(query, { deep: true }))

  if (queryString.length) {
    return `${url}?${queryString}`
  }

  return url
}

function transformRequestBody(body) {
  if (!body) {
    return null
  }

  return JSON.stringify(snakecaseKeys(body, { deep: true }))
}

async function request(method, path, { query, body, headers } = {}) {
  const url = appendQuery(path, query)

  try {
    const res = await fetch(url, {
      method,
      body: transformRequestBody(body),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
      credentials: 'include',
    })

    return await transformResponse(res)
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error, url)

      if (error.message === 'Failed to fetch') {
        logThatBackendIsDown('Network request failed!')
      }
    }

    return {
      ok: false,
      error,
    }
  }
}

class Api {
  constructor({ baseUrl, locale }) {
    this.baseUrl = baseUrl
    this.locale = locale
  }

  setLocale(locale) {
    this.locale = locale
  }

  get(path, opts = {}) {
    return this._request('GET', path, opts)
  }

  put(path, opts = {}) {
    return this._request('PUT', path, opts)
  }

  post(path, opts = {}) {
    return this._request('POST', path, opts)
  }

  delete(path, opts = {}) {
    return this._request('DELETE', path, opts)
  }

  _request(method, path, opts = {}) {
    const url = `${stripTrailingSlashes(this.baseUrl)}/${stripLeadingSlashes(
      path
    )}`

    return request(method, url, {
      ...opts,
      query: {
        locale: this.locale,
        ...opts.query,
      },
    })
  }
}

export default Api
