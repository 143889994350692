// We can't really test this file because of the async nature of React/JavaScript,
// so we ignore it from coverage:
// istanbul ignore file

import React from 'react'
import PropTypes from 'prop-types'

function EventHandlerBoomButton(textToAppend) {
  function handleClick() {
    throw new Error(`Boom: Event handler!${textToAppend}`)
  }

  return <button onClick={handleClick}>Event handler</button>
}

function PromiseRejectionBoomButton({ textToAppend }) {
  async function handleClick() {
    await Promise.resolve('all well')
    await Promise.reject(new Error(`Boom: Rejected promise!${textToAppend}`))
  }

  return <button onClick={handleClick}>Promise rejection</button>
}

PromiseRejectionBoomButton.propTypes = {
  textToAppend: PropTypes.string,
}

function BrokenStateBoomButton({ textToAppend }) {
  const [boom, setBoom] = React.useState(false)

  if (boom) {
    throw new Error(`Boom: Broken state!${textToAppend}`)
  }

  function handleClick() {
    setBoom(true)
  }

  return <button onClick={handleClick}>Broken state</button>
}

BrokenStateBoomButton.propTypes = {
  textToAppend: PropTypes.string,
}

function VengaboysBoomButton() {
  function handleClick() {
    window
      .open(
        'https://www.youtube.com/watch?v=llyiQ4I-mcQ',
        '_blank',
        'noreferrer'
      )
      ?.focus()
  }

  return <button onClick={handleClick}>Vengaboys</button>
}

export {
  EventHandlerBoomButton,
  PromiseRejectionBoomButton,
  BrokenStateBoomButton,
  VengaboysBoomButton,
}
