import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchCurrencies = createAsyncThunk(
  'currency/fetchCurrencies',
  async (_, { extra: { api } }) => {
    const response = await api.get(`/api/v2/admin/currencies`)

    if (response.ok) {
      return response.body
    }
  }
)

function transformKeys(array, fn) {
  return Object.fromEntries(
    Object.entries(array).map(([key, value]) => [fn(key), value])
  )
}

const initialState = {
  rates: {},
}

const currencySlice = createSlice({
  name: 'currency',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
      state.rates = transformKeys(action.payload.rates, (key) =>
        key.toUpperCase()
      )
    })
  },
})

export default currencySlice.reducer
