import { useMessageBus } from '~/lib/MessageBus'

// This component must be inside the <MessagingProvider>.
function HandleGlobalMessages() {
  useMessageBus('reload-clients', () => {
    const disallowReloadingFromCache = true
    const location = window.location
    location.reload(disallowReloadingFromCache)
  })

  return null
}

export default HandleGlobalMessages
