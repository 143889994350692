import PropTypes from 'prop-types'

import AbstractAuctionProvider from './AbstractAuctionProvider'

import { fetchAuction } from '~/slices/bidding'

import Context from './BiddingContext'

function BiddingAuctionProvider({ auctionId, children }) {
  return (
    <AbstractAuctionProvider
      auctionId={auctionId}
      action={fetchAuction}
      Context={Context}
      selector={(state) => state.bidding}
    >
      {children}
    </AbstractAuctionProvider>
  )
}

BiddingAuctionProvider.propTypes = {
  auctionId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default BiddingAuctionProvider
