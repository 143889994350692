import { EventEmitter } from 'events'
import { v4 as uuidv4 } from 'uuid'

import logThatBackendIsDown from '~/lib/logThatBackendIsDown'

const STATUS_CHANNEL = '/__status'
const POLL_TIMEOUT_IN_MS = 5000

class MessageBusClient extends EventEmitter {
  constructor({ baseUrl = '', pollingInterval = 300 } = {}) {
    super()

    this.baseUrl = baseUrl
    this.pollingInterval = pollingInterval

    this.isRunning = false
    this.pollTimeout = undefined
    this.channels = new Map()
    this.clientId = uuidv4().replace(/-/g, '')
    this.seq = 0
  }

  start() {
    if (this.isRunning) {
      return
    }

    this.isRunning = true
    this._poll()
  }

  stop() {
    this.isRunning = false
    clearTimeout(this.pollTimeout)
    this.pollTimeout = undefined
  }

  // Subscribe to a channel
  // if lastId is 0 or larger, it will receive messages AFTER that ID
  // if lastId is negative it will perform lookbehind
  // -1 will subscribe to all new messages
  // -2 will receive last message + all new messages
  // -3 will receive last 2 messages + all new messages
  subscribe(channel, callback, lastId = -1) {
    if (!this.isRunning) {
      this.start()
    }

    if (this.channels.has(channel)) {
      return
    }

    this.channels.set(channel, {
      callback,
      lastId,
    })
  }

  unsubscribe(channel) {
    this.channels.delete(channel)
  }

  async _poll() {
    if (!this.isRunning) {
      return
    }

    try {
      const messages = await this._requestMessages()
      this._processMessages(messages)
    } catch (e) {
      console.error(e)
    } finally {
      this.pollTimeout = window.setTimeout(
        () => this._poll(),
        this.pollingInterval
      )
    }
  }

  async _requestMessages() {
    const body = {}
    let signal = null

    for (const [channelName, channel] of this.channels) {
      body[channelName] = channel.lastId
    }

    body.__seq = ++this.seq

    const url = this._pollUrl()

    // Only add timeout when AbortSignal.timeout is supported. Safari users are sometimes slow to update.
    // https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout_static#browser_compatibility
    if (typeof AbortSignal.timeout === 'function') {
      signal = AbortSignal.timeout(POLL_TIMEOUT_IN_MS)
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'omit',
        mode: 'cors',
        headers: {
          'X-SILENCE-LOGGER': 'true',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
        signal,
      })

      if (!response.ok) {
        return []
      }

      return response.json()
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        logThatBackendIsDown('MessageBus is failing!')
      }

      console.error(e)
      return []
    }
  }

  _pollUrl() {
    return [
      this.baseUrl.replace(/\/+$/, ''),
      'message-bus',
      this.clientId,
      'poll?dlp=t',
    ].join('/')
  }

  _processMessages(messages) {
    for (const message of messages) {
      if (this._processStatusMessage(message)) {
        continue
      }

      const channel = this.channels.get(message.channel)

      if (!channel?.callback) {
        continue
      }

      channel.lastId = message.message_id

      try {
        channel.callback(message.data, message.global_id, message.message_id)
      } catch (e) {
        console.error('MessageBus callback error', message.channel, e)
      }
    }
  }

  _processStatusMessage(message) {
    if (message.channel !== STATUS_CHANNEL) {
      return false
    }

    const data = message.data

    for (const [channelName, lastId] of Object.entries(data)) {
      const channel = this.channels.get(channelName)

      if (channel) {
        channel.lastId = lastId
      }
    }

    const status = Object.fromEntries(
      Array.from(this.channels.entries(), ([key, { lastId }]) => [key, lastId])
    )

    this.emit('status', status)

    return true
  }
}

export default MessageBusClient
