import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useI18n } from '@barsoom/lengua'

import { SUPPORTED_LOCALES } from '~/lib/getSupportedLocale'

import styles from './LanguageSwitcher.module.css'

function LanguageSwitcher() {
  const location = useLocation()
  const history = useHistory()
  const I18n = useI18n()

  const active = I18n.locale.language

  function handleChange(e) {
    const path = location.pathname.replace(`/${active}`, `/${e.target.value}`)

    history.push(path + location.search)
  }

  const options = SUPPORTED_LOCALES.map((locale) => {
    return (
      <option key={locale} value={locale}>
        {locale}
      </option>
    )
  })

  return (
    <select
      className={styles.languageSwitcher}
      value={active}
      onChange={handleChange}
    >
      {options}
    </select>
  )
}

export default LanguageSwitcher
