import { useI18n } from '@barsoom/lengua'
import { Helmet } from 'react-helmet-async'

function HtmlLocaleUpdater() {
  const I18n = useI18n()

  const language = I18n.locale.language

  return (
    <Helmet>
      <html lang={language} />
    </Helmet>
  )
}

export default HtmlLocaleUpdater
