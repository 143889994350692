import configureHoneybadger from './configureHoneybadger'
import { DEFAULT_LOCALE } from '~/lib/getSupportedLocale'

export const mockBackendBaseUrl = 'https://live-bidding-api.localhost/backend'

async function fetchConfig(path) {
  const res = await fetch(path)

  return res.json()
}

function shouldEnableMockApi() {
  return document.location.search.includes('mockApi')
}

class Configuration {
  static async load(path) {
    const config = await fetchConfig(path)

    return new Configuration(config)
  }

  constructor(options) {
    this.auctionetBaseUrl = options.auctionetBaseUrl
    this.backendBaseUrl = options.backendBaseUrl
    this.messageBusPollingInterval = options.messageBusPollingInterval
    this.initialLocale = options.initialLocale || DEFAULT_LOCALE

    this.honeybadger = configureHoneybadger(
      options.honeybadgerEnv || process.env.NODE_ENV
    )

    const searchParams = new URLSearchParams(document.location.search)

    this.inAppShell = searchParams.get('inAppShell') === 'true'

    this.envBar = options.envBar || {
      label: 'N/A',
      background: '#f0f',
      visible: 'localStorage',
    }

    if (shouldEnableMockApi()) {
      this.enableMockApi = true
      this.backendBaseUrl = mockBackendBaseUrl
    } else {
      this.enableMockApi = false
    }
  }
}

export default Configuration
