import createLogger from '~/lib/createLogger'

function createFilterFn(keysToFilter) {
  return function filterObject(obj) {
    if (Array.isArray(obj)) {
      return obj.map(filterObject)
    }

    if (obj && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) =>
          keysToFilter.includes(key)
            ? [key, '[FILTERED]']
            : [key, filterObject(value)]
        )
      )
    }

    return obj
  }
}

const logger = createLogger('HoneybadgerMiddleware', '#ea5937')

function createHoneybadgerMiddleware(honeybadger) {
  const filterObject = createFilterFn(['streaming', 'relatedLots'])

  function logAction(action) {
    if (honeybadger.config?.debug) {
      logger.debug('logAction', action)
    }

    const { type, ...metadata } = action

    honeybadger.addBreadcrumb(type, {
      category: 'redux',
      metadata,
    })
  }

  return (_api) => (next) => (action) => {
    if (typeof action === 'object') {
      logAction(filterObject(action))
    }

    next(action)
  }
}

export default createHoneybadgerMiddleware
