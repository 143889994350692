import { useState } from 'react'

import NarrowLayout from '~/components/NarrowLayout'

import {
  EventHandlerBoomButton,
  PromiseRejectionBoomButton,
  BrokenStateBoomButton,
  VengaboysBoomButton,
} from './buttons'

function BoomPage() {
  const [textToAppend, setTextToAppend] = useState('')

  return (
    <NarrowLayout>
      <h2>Boom page</h2>

      <p>Choose your boom</p>

      <p>
        <label>
          Append to error message:{' '}
          <input
            value={textToAppend}
            onInput={(e) => setTextToAppend(e.target.value)}
          />
        </label>
      </p>

      <ul>
        <li>
          <EventHandlerBoomButton textToAppend={textToAppend} />
        </li>
        <li>
          <PromiseRejectionBoomButton textToAppend={textToAppend} />
        </li>
        <li>
          <BrokenStateBoomButton textToAppend={textToAppend} />
        </li>
        <li>
          <VengaboysBoomButton />
        </li>
      </ul>
    </NarrowLayout>
  )
}

export default BoomPage
