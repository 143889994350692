import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor'

import { EventEmitter } from 'events'

import createLogger from '~/lib/createLogger'

const logger = createLogger('VideoPlayer', '#aaccf6')

function setUpWebRTCAdaptor({
  videoElementId,
  callback,
  callbackError,
  config,
}) {
  const websocketUrl = config.websocketUrl

  const peerConnectionConfig = {
    iceServers: config.iceServers,
  }

  const sdpConstraints = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true,
  }

  const mediaConstraints = {
    video: false,
    audio: false,
  }

  return new WebRTCAdaptor({
    websocket_url: websocketUrl,
    mediaConstraints: mediaConstraints,
    peerconnection_config: peerConnectionConfig,
    sdp_constraints: sdpConstraints,
    isPlayMode: true,
    remoteVideoId: videoElementId,
    callback,
    callbackError,
  })
}

class Client extends EventEmitter {
  #webRTCAdaptor = null
  #config = null
  #restartStreamTimer = null
  #videoElementId = null
  #isShutdown = false

  constructor(config, videoElementId) {
    super()
    this.#config = config
    this.#videoElementId = videoElementId

    this._setUp()
  }

  _setUp() {
    if (this.#isShutdown) {
      throw new Error('Client been shut down')
    }

    if (this.#webRTCAdaptor) {
      throw new Error(
        'Attempting to set up a client that has already been set up'
      )
    }

    logger.log('setUp()')

    this.#webRTCAdaptor = setUpWebRTCAdaptor({
      config: this.#config,
      videoElementId: this.#videoElementId,
      callback: this._webRTCAdaptorCallback.bind(this),
      callbackError: this._webRTCAdaptorCallbackError.bind(this),
    })
  }

  restartStream({ timeout = 2500, force = false } = {}) {
    if (this.#restartStreamTimer) {
      logger.log('Attempting to restart while there is an active timer')
      return
    }

    if (force === false) {
      const video = this.#webRTCAdaptor?.remoteVideo

      if (video && video.paused && video.readyState >= 2) {
        logger.log('Can not restart stream because it is playing')
        return
      }
    }

    logger.log('Restarting stream in', timeout, 'ms...')

    this.#restartStreamTimer = setTimeout(() => {
      logger.log('Restarting stream')

      this.emit('restartingStream')

      this.stop()
      this.#webRTCAdaptor.closeWebSocket()
      this.#webRTCAdaptor = null
      this._setUp()

      this.#restartStreamTimer = null
    }, timeout)
  }

  clearRestartStreamTimer() {
    clearTimeout(this.#restartStreamTimer)
    this.#restartStreamTimer = null
  }

  shutdown() {
    logger.log('shutdown()')
    this.#isShutdown = true
    this.clearRestartStreamTimer()
    this.removeAllListeners()
    this.stop()
    this.#webRTCAdaptor.closeWebSocket()
  }

  async play() {
    this.#webRTCAdaptor.play(this.#config.streamId, this.#config.streamToken)

    try {
      await this.#webRTCAdaptor.remoteVideo?.play()
    } catch (e) {
      logger.error(e)
    }
  }

  pause() {
    this.#webRTCAdaptor.remoteVideo?.pause()
  }

  stop() {
    this.#webRTCAdaptor.stop(this.#config.streamId)
    this.pause()
  }

  setQuality(quality) {
    this.#webRTCAdaptor.forceStreamQuality(this.#config.streamId, quality)
  }

  _webRTCAdaptorCallback(info, data) {
    if (this.#isShutdown) {
      logger.log('webRTCAdaptorCallback: returning because shutting down')
      return
    }

    switch (info) {
      case 'initialized':
        this.#webRTCAdaptor.getStreamInfo(this.#config.streamId)
        break
      case 'streamInformation':
        this.clearRestartStreamTimer()
        this.emit('streamInformation', data)
        break
      case 'play_started':
        this.clearRestartStreamTimer()
        this.play()
        this.emit('playStarted', data)
        break
      case 'play_finished':
        this.emit('playFinished', data)
        this.restartStream()
        break
      case 'closed':
        this.emit('closed', data)
        this.restartStream()
        break
      case 'server_will_stop':
        this.emit('serverWillStop', data)
        this.restartStream({ timeout: 0 })
        break
      case 'bitrateMeasurement':
        this.emit('bitrateMeasurement', data)
        break
      default:
        break
    }
  }

  _webRTCAdaptorCallbackError(error) {
    if (this.#isShutdown) {
      logger.log('webRTCAdaptorCallbackError: returning because shutting down')
      return
    }

    if (error?.target instanceof WebSocket) {
      logger.error('WebSocket error')
      this.restartStream({ force: true })
      return
    }

    switch (error) {
      case 'no_stream_exist':
      case 'data_store_not_available':
      case 'not_initialized_yet':
        this.restartStream({ force: true })
        break
      default:
      // Nothing
    }
  }
}

export default Client
