import PropTypes from 'prop-types'
import Context from './Context'

function ConfigurationProvider({ configuration, children }) {
  if (!configuration) {
    throw new Error('Configuration not provided to ConfigurationProvider')
  }

  return <Context.Provider value={configuration}>{children}</Context.Provider>
}

ConfigurationProvider.propTypes = {
  configuration: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default ConfigurationProvider
