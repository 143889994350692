import PropTypes from 'prop-types'

import { I18nProvider } from '@barsoom/lengua'

import ApiLocaleUpdater from './ApiLocaleUpdater'
import HtmlLocaleUpdater from './HtmlLocaleUpdater'

import * as TRANSLATIONS from '~/translations/wti'

function TranslationProvider({ locale, children }) {
  const translations = TRANSLATIONS[locale]

  if (!translations) {
    throw new Error(`No translations for locale: ${locale}`)
  }

  // Currently unused, but I18nProvider requires us to pass in the property.
  // See discussion: https://auctionet.slack.com/archives/C02GX6QP90Q/p1637246430023500?thread_ts=1637241458.010200&cid=C02GX6QP90Q
  const dateLocale = {}

  return (
    <I18nProvider
      localeName={translations.localeName}
      translations={translations}
      dateLocale={dateLocale}
    >
      <HtmlLocaleUpdater />
      <ApiLocaleUpdater />
      {children}
    </I18nProvider>
  )
}

TranslationProvider.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TranslationProvider
