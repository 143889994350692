import { useContext } from 'react'

import Context from './Context'

function useConfiguration() {
  const configuration = useContext(Context)

  if (!configuration) {
    throw new Error(
      'Need to be inside <ConfigurationProvider> to use useConfiguration'
    )
  }

  return configuration
}

export default useConfiguration
