import { createAction } from '@reduxjs/toolkit'

export const replaceAuction = createAction('shared/replaceAuction')

export function errorsFromPayload(payload, fallbackMessage) {
  return payload?.errors || [fallbackMessage]
}

export function auctionFromPayload(payload) {
  const { relatedLots, ...auction } = payload

  function lotOrNull(lot) {
    if (lot && Object.prototype.hasOwnProperty.call(lot, 'lotNumber')) {
      return lot
    }

    return null
  }

  // relatedLots always has lot slots before and after the current lot.
  // If the current lot is e.g. first in the auction, it will be preceded by null slots.
  return {
    ...auction,
    relatedLots: (relatedLots || []).map(lotOrNull),
  }
}

export const YOUR_BID_STATUS = {
  leading: 'leading',
  pending: 'pending',
  outbid: 'outbid',
  absenteeBidLead: 'absentee_bid_lead',
}
