import PropTypes from 'prop-types'

import VolumeIcon from './VolumeIcon'

import styles from './VolumeButton.module.css'

function VolumeButton({ volume, isMuted, onClick, title }) {
  return (
    <button
      title={title}
      onClick={onClick}
      className={styles.volumeButton}
      data-volume={volume}
    >
      <VolumeIcon volume={volume} isMuted={isMuted} />
    </button>
  )
}

VolumeButton.propTypes = {
  volume: PropTypes.number.isRequired,
  isMuted: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default VolumeButton
