import PropTypes from 'prop-types'

import RangeSlider from './RangeSlider'
import VolumeButton from './VolumeButton'
import DevCrashButton from './DevCrashButton'

import IconButton from './IconButton'

import { useI18n } from '@barsoom/lengua'

import styles from './Controls.module.css'

function Controls({
  isPaused,
  isMuted,
  volume,
  showVolumeSlider,
  onToggleMute,
  onChangeVolume,
  onTogglePlayPause,
}) {
  const I18n = useI18n('player.controls')

  const showLargePlayButton = isPaused
  const showDevCrashButton = process.env.NODE_ENV === 'development'

  return (
    <>
      {showDevCrashButton && <DevCrashButton />}
      {showLargePlayButton && (
        <IconButton
          icon="play"
          title={I18n.t('.play')}
          className={styles.largePlayButton}
          onClick={onTogglePlayPause}
        />
      )}
      <div className={styles.controls}>
        <IconButton
          title={I18n.t(isPaused ? '.play' : '.pause')}
          icon={isPaused ? 'play' : 'pause'}
          onClick={onTogglePlayPause}
        />
        <div className={styles.rightSideControls}>
          <VolumeButton
            title={I18n.t(isMuted ? '.unmute' : '.mute')}
            onClick={onToggleMute}
            volume={volume}
            isMuted={isMuted}
          />
          {showVolumeSlider && (
            <RangeSlider
              title={I18n.t('.volume')}
              value={isMuted ? 0 : volume}
              min={0}
              max={1.0}
              step={0.01}
              onChange={onChangeVolume}
            />
          )}
        </div>
      </div>
    </>
  )
}

Controls.propTypes = {
  isPaused: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  showVolumeSlider: PropTypes.bool.isRequired,
  onToggleMute: PropTypes.func.isRequired,
  onChangeVolume: PropTypes.func.isRequired,
  onTogglePlayPause: PropTypes.func.isRequired,
}

export default Controls
