import PropTypes from 'prop-types'

function Spinner({
  numPoints = 9,
  pointRadius = 0.2,
  speed = 1,
  color = '#000',
  className,
}) {
  const pointDistanceFromCenter = 1.0 - pointRadius

  const points = Array.from({ length: numPoints }, (_, i) => {
    const theta = (i / numPoints) * Math.PI * 2

    const x = Math.cos(theta) * pointDistanceFromCenter
    const y = Math.sin(theta) * pointDistanceFromCenter

    const opacity = (i + 1) / (numPoints + 1)

    return (
      <circle
        key={i}
        cx={x}
        cy={y}
        opacity={opacity}
        r={pointRadius}
        fill={color}
      />
    )
  })

  const animationSteps = Array.from(
    { length: numPoints },
    (_, i) => (360 * i) / numPoints
  ).join(';')

  return (
    <svg viewBox="-1 -1 2 2" className={className} width="100%">
      <g>
        {points}
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur={`${speed}s`}
          calcMode="discrete"
          values={animationSteps}
        />
      </g>
    </svg>
  )
}

Spinner.propTypes = {
  numPoints: PropTypes.number,
  pointRadius: PropTypes.number,
  speed: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}

export default Spinner
