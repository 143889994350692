// This is for code-splitting: https://reactjs.org/docs/code-splitting.html
// If you change this, also see pages/__mocks__/index.js.

import { lazy } from 'react'

const pages = Object.fromEntries(
  // Uses https://vitejs.dev/guide/features.html#glob-import.
  Object.entries(import.meta.glob('./*/index.js')).map(([path, importFn]) => [
    path.split('/')[1],
    lazy(importFn),
  ])
)

export default pages
