import { YOUR_BID_STATUS } from '~/slices/shared'

export default function getYourLastBid({ bids, paddleNumber }) {
  if (!paddleNumber) return null

  const yourLastBid = bids.find((bid) => bid.paddle === paddleNumber)
  if (!yourLastBid) return null

  const leadingBid = bids.find((bid) => bid.approved)

  let status

  if (leadingBid?.id === yourLastBid.id) {
    status = YOUR_BID_STATUS.leading
  } else if (!yourLastBid.approved && yourLastBid.amount > leadingBid.amount) {
    status = YOUR_BID_STATUS.pending
  } else {
    status = YOUR_BID_STATUS.outbid
  }

  return {
    ...yourLastBid,
    status,
  }
}
