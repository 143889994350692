import React from 'react'

import App, { Routes, createStore } from '~/App'

import setUpMockApiInBrowser from '~/mocks/setUpMockApiInBrowser'

import './index.css'

async function setUpApp(configuration) {
  // If NODE_ENV does not equal 'development', the entire mock API stuff will be tree-shaken away.
  if (process.env.NODE_ENV === 'development' && configuration.enableMockApi) {
    await setUpMockApiInBrowser()
  }

  const store = createStore(configuration)

  return (
    <React.StrictMode>
      <App store={store} configuration={configuration}>
        <Routes />
      </App>
    </React.StrictMode>
  )
}

export default setUpApp
