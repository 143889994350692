import PropTypes from 'prop-types'

import AbstractAuctionProvider from './AbstractAuctionProvider'

import { fetchAuction } from '~/slices/admin'

import Context from './AdminContext'

function AdminAuctionProvider({ auctionId, children }) {
  return (
    <AbstractAuctionProvider
      auctionId={auctionId}
      action={fetchAuction}
      Context={Context}
      selector={(state) => state.admin}
    >
      {children}
    </AbstractAuctionProvider>
  )
}

AdminAuctionProvider.propTypes = {
  auctionId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default AdminAuctionProvider
