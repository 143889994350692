import { useI18n } from '@barsoom/lengua'
import ErrorPage from '~/pages/ErrorPage'

function NotFoundPage() {
  const I18n = useI18n('errorPage.notFound')

  return <ErrorPage title={I18n.t('.title')} body={I18n.t('.body')} />
}

export default NotFoundPage
