import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMessageBus } from '~/lib/MessageBus'

import { replaceAuction } from '~/slices/shared'

function getChannelName(auctionId, eventName) {
  return `auction-${auctionId}-${eventName}`
}

function useAuctionMessages(auctionId) {
  const dispatch = useDispatch()

  // The messages correspond to the ones published by live-bidding-backend.
  // See it's readme for details on how they are published.
  useMessageBus(
    getChannelName(auctionId, 'auction-updated'),
    useCallback((auction) => dispatch(replaceAuction(auction)), [dispatch])
  )
}

export default useAuctionMessages
